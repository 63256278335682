let burger = document.querySelector('.js-menu__btn')
let menu = document.querySelector('.js-aside')
let burgerClose = document.querySelector('.js-menu__close')
let headerOverlay = document.querySelector('.js-header-overlay')
let body = document.querySelector('body')
let header = document.querySelector('.js-header')

burger.addEventListener('click', function(){
  menu.classList.toggle('is-active')
  headerOverlay.classList.toggle('is-active')
  body.classList.toggle('no-scroll')
  body.classList.toggle('move')
})
if(window.matchMedia("(max-width: 1400px)").matches){
  burgerClose.addEventListener('click', function(){
    menu.classList.remove('is-active')
    headerOverlay.classList.remove('is-active')
    body.classList.remove('no-scroll')
    body.classList.remove('move')
  })
}

if(window.matchMedia("(min-width: 1400px)").matches){
  burgerClose.addEventListener('click', function(){
    menu.classList.toggle('is-active');
    body.classList.toggle('move');
    header.classList.toggle('move');
  })
}
headerOverlay.addEventListener('click', function(event){
  if(event.target.className === headerOverlay.className){
    menu.classList.remove('is-active')
    headerOverlay.classList.remove('is-active')
    body.classList.remove('no-scroll')
    body.classList.remove('move')
  }
})

